import React from "react";
import bgiconhome from './../../assets/images/home/bgiconhome.png';
import easytouse from './../../assets/images/home/easy to use.png';
import multipledevice from './../../assets/images/home/multiple device.png';
import customfeature from './../../assets/images/home/custom features.png';
import multiplestore from './../../assets/images/home/Multiple store.png';
import realtimereport from './../../assets/images/home/real time report.png';

function Hero() {
  return (
    <section id="hero" className="d-flex align-items-center justify-content-center">
    <div className="container" data-aos="fade-up">

      <div className="row justify-content-left" data-aos="fade-up" data-aos-delay="150">
        <div className="col-xl-6 col-lg-8">
          <h1>Powerful Solution for YOUR BUSINESS<span>.</span></h1>
          <h2>Application that make your business auto pilot</h2>
        </div>
        <div className="col-xl-6 col-lg-6">
                <img
                  src={bgiconhome}
                  alt="BB Icon"
                  className="img-fluid img-responsive"
                  
                />
        </div>
      </div>

      <div className="row gy-4 mt-5 justify-content-center" data-aos="zoom-in" data-aos-delay="250">
        <div className="col-xl-2 col-md-4">
          <div className="icon-box">
                <img
                  src={easytouse}
                  alt="BB easy to use"
                  className="img-fluid img-responsive"
                  style={{maxHeight:"50px"}}
                />

            <h3 style={{fontSize:"12px"}}><a href="#">Easy to Use</a></h3>
          </div>
        </div>
        <div className="col-xl-2 col-md-4">
          <div className="icon-box">
                <img
                  src={multipledevice}
                  alt="BB easy to use"
                  className="img-fluid img-responsive"
                  style={{maxHeight:"50px"}}
                />
            <h3 style={{fontSize:"12px"}}><a href="#">Multiple Device</a></h3>
          </div>
        </div>
        <div className="col-xl-2 col-md-4">
          <div className="icon-box">
                <img
                  src={customfeature}
                  alt="BB easy to use"
                  className="img-fluid img-responsive"
                  style={{maxHeight:"50px"}}
                />
            <h3 style={{fontSize:"12px"}}><a href="#">Custom Features</a></h3>
          </div>
        </div>
        <div className="col-xl-2 col-md-4">
          <div className="icon-box">
                <img
                  src={multiplestore}
                  alt="BB easy to use"
                  className="img-fluid img-responsive"
                  style={{maxHeight:"50px"}}
                />
            <h3 style={{fontSize:"12px"}}><a href="#">Multiple Store</a></h3>
          </div>
        </div>
        <div className="col-xl-2 col-md-4">
          <div className="icon-box">
                <img
                  src={realtimereport}
                  alt="BB easy to use"
                  className="img-fluid img-responsive"
                  style={{maxHeight:"50px"}}
                />
            <h3 style={{fontSize:"12px"}}><a href="#">Real Time Report</a></h3>
          </div>
        </div>
      </div>

    </div>
  </section>
  );
}

export default Hero;
