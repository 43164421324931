import React, { useState } from "react";
import logo from './../../assets/images/logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import OTPInput from "otp-input-react";
import axios from './../../app/axiosbb';


function RegisterForm() {
    const [OTP, setOTP] = useState("");
    const [showOTP, setShowOTP] = useState("none");
    const [showLoadingreg, setShowLoadingreg] = useState(false);
    const [showLoadingotp, setShowLoadingotp] = useState(false);

    const [showpPopup, setShowPopup] = useState("none");

    const [contentdata, setContentdata] = useState('');
    const [errormessage, setErrormessage] = useState('');
    const [errormessageotp, setErrormessageotp] = useState('');
    const handleChange = (event) => {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        //const element = document.getElementById(target.id);
    
        //validateInputchange(element,target,name,value);
        setContentdata({ ...contentdata, [name]: value });
        //console.log(contentdata);
    }


    function handleVerifyOTP() {
        //console.log(OTP);
        let jsonData =  JSON.stringify(contentdata);
        const obj = JSON.parse(jsonData);
        obj.OTP=OTP;

        if(typeof obj ==="undefined")
        {
            setErrormessageotp("Please filled in the form");
            return;
        }

        if(typeof obj.OTP==="undefined")
        {
            setErrormessageotp("Please filled in the form");
            return;
        }

        if(obj.OTP==="" || obj.OTP.length<=0)
        {
            setErrormessageotp("Please filled in the form");
            return;
        }

        let url="useraync/reg/validateotpbg";

       // console.log(obj);
        setShowLoadingotp(true);
        return axios.post(url, obj).then(res => {
            if (res.status === 200 || res.status === 201) {
                setShowLoadingotp(false);
                //setErrormessage("Succesfully Register");
                setErrormessage("Processing Registration,we will notify you by email , Thanks.");
                setErrormessageotp("");
                setShowOTP("none");
                setOTP("");
                setContentdata('');
                setShowPopup("block");
            }
            else{
                setShowLoadingotp(false);
                setErrormessageotp("Invalid Data");
                return;
            }
        }).catch(err => {
                setShowLoadingotp(false);
                setErrormessageotp("Complete the data,or data is invalid");   
                return ;
          });
    }

    function handleSave() {
        let jsonData =  JSON.stringify(contentdata);
        // console.log(jsonData);
        //console.log(jsonData.length);
        if(jsonData==='""')
        {
            setErrormessage("Please filled in the form");
            return;
        }

        const obj = JSON.parse(jsonData);

        // console.log(obj.FirstName);
        // console.log(obj.CategoryCode);
        // console.log(obj.ShopName);
        // console.log(obj);
         //console.log(obj);

        if(typeof obj ==="undefined")
        {
            setErrormessage("Please filled in the form");
            return;
        }

        if(typeof obj.FirstName==="undefined")
        {
            setErrormessage("Please filled in the form");
            return;
        }

        if(typeof obj.CategoryCode==="undefined")
        {
            setErrormessage("Please filled in the form");
            return;
        }

        if(typeof obj.ShopName==="undefined")
        {
            setErrormessage("Please filled in the form");
            return;
        }

        if(typeof obj.UserName==="undefined")
        {
            setErrormessage("Please filled in the form");
            return;
        }

        if(typeof obj.Password==="undefined")
        {
            setErrormessage("Please filled in the form");
            return;
        }

        if(typeof obj.MobileNumber==="undefined")
        {
            setErrormessage("Please filled in the form");
            return;
        }

        if(obj.FirstName==="" || obj.FirstName.length<=0)
        {
            setErrormessage("Please filled in the form");
            return;
        }

        if(obj.CategoryCode==="" || obj.CategoryCode.length<=0)
        {
            setErrormessage("Please filled in the form");
            return;
        }

        if(obj.ShopName==="" || obj.ShopName.length<=0)
        {
            setErrormessage("Please filled in the form");
            return;
        }

        if(obj.UserName==="" || obj.UserName.length<=0)
        {
            setErrormessage("Please filled in the form");
            return;
        }

        if(obj.Password==="" || obj.Password.length<=0)
        {
            setErrormessage("Please filled in the form");
            return;
        }

        if(obj.rePassword==="" || obj.rePassword.length<=0)
        {
            setErrormessage("Please filled in the form");
            return;
        }

        if(obj.Password !== obj.rePassword)
        {
            setErrormessage("Please filled in the form");
            return;
        }

        if(obj.MobileNumber==="" || obj.MobileNumber.length<=0)
        {
            setErrormessage("Please filled in the form");
            return;
        }

        let url="/user/reg";
        setShowLoadingreg(true);
        return axios.post(url, obj).then(res => {
            if (res.status === 200 || res.status === 201) {
                setErrormessage("");
                setShowLoadingreg(false);
                setShowOTP("block");
            }
            else{
                setShowLoadingreg(false);
                setErrormessage("Invalid Username or Password");
                return;
            }
        }).catch(err => {
                setShowLoadingreg(false);
                setErrormessage("Complete the data , either change the data or data is invalid");  
                return ;
        });

        
    }

    function handleOK() {
        setShowPopup("none");
        window.location.href = "/";
    }

    function popUpshow(){
        return (
            <div className="popup" id="popup1" style={{left:"28%",display:showpPopup}}>
                <div className="container">
                    <header>
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                style={{ fontSize: 40, color: "#FFFFFF" }}
                            />
                    </header>
                    <p>&nbsp;</p>
                    <h4>Thank You ,we are processing your registration,once it is done,we will notify you by email.</h4>
                    <p>&nbsp;</p>
                    <form>
                        
                        <button type="button" onClick={handleOK}  style={{backgroundColor:"#0e4bf1"}}>OK</button>
                        
                    </form>
                </div>
            </div>
        );
    }

    function popOTP(){
        return (
            <div className="popup" id="popup1" style={{display:showOTP}}>
                <div className="container">
                    <header>
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                style={{ fontSize: 40, color: "#FFFFFF" }}
                            />
                    </header>
                    <p>&nbsp;</p>
                    <h4>Enter OTP Code</h4>
                    <p>&nbsp;</p>
                    <form>
                        <div className="input-field">
                            <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} />
                        </div>
                        <button type="button" onClick={handleVerifyOTP} disabled={OTP.length < 6} style={{backgroundColor:OTP.length<6?"#6e93f7":"#0e4bf1"}}>Verify OTP</button>
                        <div className="text-center">
                            <p>{errormessageotp}</p>
                        </div>
                    </form>
                </div>
                <p className="text-center">Check your inbox or spam folder to get the OTP Code.</p>
                <div style={{display:!showLoadingotp?"none":"inline"}}>                                               
                    <p>Processing ... </p><div className="loader"></div>
                </div>
            </div>
        );
    }

  return (
    <section id="contact" className="contact">
        {popUpshow()}
        {popOTP()}
        <div className="container" data-aos="fade-up" style={{marginTop:"30px"}}>
            <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body" style={{padding:"unset"}}> 
                                <div className="row">
                                    <div className="col-lg-6 text-center" style={{padding:"30px"}}>
                                        <div className="text-center">
                                            <img src={logo}
                                                style={{width:"185px"}}alt="logo"/>
                                            <h4 className="mt-1 mb-5 pb-1">&nbsp;</h4>
                                        </div>
                                        <form>
                                            <p>Please do Register</p>
                                            <div className="form-outline mb-4">
                                                <input type="text" id="FirstName" name="FirstName" className="form-control"
                                                placeholder="Nama Anda" onChange={handleChange}/>
                                            </div>
                                            <div className="form-outline mb-4">
                                                <input type="text" id="ShopName" name="ShopName" className="form-control"
                                                placeholder="Nama Toko /  Nama Brand" onChange={handleChange}/>
                                            </div>
                                            <div className="form-outline mb-4">
                                                <input type="text" id="MobileNumber" name="MobileNumber" className="form-control"
                                                placeholder="Mobile number" onChange={handleChange}/>
                                            </div>
                                            <div className="form-outline mb-4">
                                                <input type="email" id="UserName" name="UserName" className="form-control"
                                                placeholder="Email address" onChange={handleChange}/>
                                            </div>
                                            
                                            <div className="form-outline mb-4">
                                                <input type="password" id="Password" name="Password" className="form-control" 
                                                placeholder="Password" onChange={handleChange}/>
                                            </div>
                                            <div className="form-outline mb-4">
                                                <input type="password" id="rePassword" name="rePassword" className="form-control" 
                                                placeholder="Repeat Password" onChange={handleChange}/>
                                            </div>
                                            <div className="form-outline mb-4">
                                                <select name="CategoryCode" id="CategoryCode" placeholder="select Category" 
                                                className="form-control" onChange={handleChange}>
                                                    <option value="">Select Category</option>
                                                    <option value="FNB">FnB</option>
                                                    <option value="RTL">Wholesale or Retail</option>
                                                    <option value="SRV">Service</option>
                                                    <option value="WRH">Warehouse Management</option>
                                                    <option value="TAX">Tax Management</option>
                                                </select>
                                            </div>
                                            <div className="text-center">
                                                <p>{errormessage}</p>
                                            </div>
                                            <div className="text-center">
                                                <button className="btn gradientcustom" type="button" 
                                                style={{width:"150px",color:"white"}} onClick={handleSave}>
                                                    Register
                                                </button>
                                               
                                                <br/>
                                            </div>
                                            <div className="flexalign" style={{display:!showLoadingreg?"none":"inline"}}>                                               
                                                <p>Processing ... </p><div className="loader"></div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-lg-6 d-flex align-items-center">
                                        <div className="gradientcustom">
                                            <div className="d-flex flex-column  justify-content-center gradient-custom-2 h-100 mb-4">
                                                <div className="text-white text-align-center px-3 py-4 p-md-5 mx-md-4">
                                                    <h4 className="mb-4 text-center">Register</h4>
                                                    <p className="small mb-0 text-center">register , you are ONE step away from getting pampered
                                                    by our no nonsense app. Manage your business easily , get analyse your business anytime , anywhere and dont forget to drop us testimony.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        
                        
                    
                
            </div>
        </div>
    </section>
  );
}

export default RegisterForm;
