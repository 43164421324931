import React, { useState, useEffect } from "react";

//import { useRouter } from "react/router"

import logo from './../../assets/images/logo.png';



import { Nav, Navbar, NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";

function Header() {
  //const router = useRouter();

  
  const [scrolled, setScrolled] = useState(false);
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const header = document.querySelector("header");
      const fixedNav = header.offsetTop;

      //console.log(header, fixedNav);
      if (window.scrollY > fixedNav) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, []);

  function handleToggle() {
    setToggled(!toggled);
  }  

  return (
    <header id="header" className={scrolled===true ? "fixed-top header-scrolled" : "fixed-top"} style={{padding:"20px"}}> 
      
      <Navbar collapseOnSelect expand="sm" variant="light">
            <Navbar.Toggle aria-controls="navbarScroll" data-bs-toggle="collapse" data-bs-target="#navbarScroll" />
            <Navbar.Brand>
              <a href="#/"><img src={logo} alt="" className="img-fluid" width="100"/></a>
            </Navbar.Brand>
            <Navbar.Collapse id="navbarScroll" style={{margin:"0 auto"}}>
                <Nav style={{margin:"0 auto"}}>
                    <NavLink  eventKey="1" as={Link} to="/">Home</NavLink>
                    <NavLink  eventKey="2" as={Link} to="/service">Services</NavLink>
                    <NavLink  eventKey="3" as={Link} to="/pricing">Pricing</NavLink>
                    <NavLink  eventKey="4" as={Link} to="/contact">Contact</NavLink>
                    <a href="#/login" className="get-started-btn scrollto" style={{padding:"7px 25px 8px 25px"}}>Get Started</a>
                </Nav>
            </Navbar.Collapse>    
            <Navbar.Brand>
              
            </Navbar.Brand> 
        </Navbar>
      
      
    </header>
  );
}

export default Header;
