//import React from 'react';
import axios from 'axios';
//import { useLocalStorage } from '@rehooks/local-storage';
//import {configureStore} from './../store/configureStore';

//import {callConfig} from 'config/config';

//const config = window.callConfig();
//console.log(process.env.NODE_ENV);
//console.log(process.env.REACT_APP_DEV_BASEURL);
//console.log(process.env.REACT_APP_PROD_BASEURL);

let BaseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_BASEURL : process.env.REACT_APP_PROD_BASEURL

const instance = axios.create({
     baseURL: BaseUrl,
    headers: {
        'Content-Type' : 'application/json',
        'Access-Control-Allow-Origin': '*',
        //'tenantID' : tenantID,
        'tenantID' : "tenant",//config.tenant,
    }
});





// const store = configureStore();
// instance.interceptors.request.use( req => {
        

//         //console.log("auth token : "+window.localStorage.getItem("token"));
//         //if(store.getState().auth.token !== null){
//         if(window.localStorage.getItem("token") !== null){
//             if (window.localStorage.getItem("token") === null || window.localStorage.getItem("token") === undefined){
//                 console.log('no token');
//                 // throw 'no token';
//             }else {
//                 //console.log('auth token is :' + localStorage.getItem("token"));
//                 req.headers['Authorization'] =localStorage.getItem("token"); //'Bearer ' + localStorage.getItem("token");
//                 //console.log('req auth token is :' + req.headers['Authorization']);
//             }
//         }
//         return req;
//     }, error => {
//         if(!error.response.status){
//             console.log('network error');
//         }
//     }
// );

instance.interceptors.response.use( res => res, error => {
    //console.log('error rooc axios', error);
    if(error.response){
        return Promise.reject(error.response);
    }
    else{

        return Promise.reject(error);
    }
});


export default instance;
