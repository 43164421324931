import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function HeaderContact() {
  return (
    <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">

            <div className="section-title">
                <h2>Contact</h2>
                <p>Contact Us</p>
            </div>
            <div>
                <iframe style={{border:"none",width:"100%",height:"270px"}} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" title="google location"></iframe>
            </div>

            <div className="row">
                <div className="col-lg-4">
                    <div className="info">
                        <div className="address">
                            <i className="bi bi-geo-alt"></i>
                            <h4>Location:</h4>
                            <p>Tanah Abang</p>
                        </div>

                        <div className="email">
                            <i className="bi bi-envelope"></i>
                            <h4>Email:</h4>
                            <p>info@bukubon.com</p>
                        </div>

                        <div className="phone">
                            <i className="bi bi-phone"></i>
                            <h4>Call:</h4>
                            <p>+62 813 143 99923</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8" style={{border:"4px dotted #3C3B3B",textAlign:"center",paddingTop:"20px"}}>
                    <div className="footer-info">
                      <img
                        src={`/assets/images/bkb-logo-white-sm.png`}
                        alt="bukubon"
                        width="163"
                        height="120"
                      />
                      <div className="social-links mt-3" style={{marginLeft:"25px"}}>
                        <a href="https://api.whatsapp.com/send?phone=+6281314399923&text=Hello,Admin saya ingin menanyakan hal mengenai bukubon" className="wa admin 1" style={{marginLeft:"0px",marginRight:"20px"}}>
                            <FontAwesomeIcon
                              icon={faWhatsapp}
                              style={{ fontSize: 20, color: "#ffc451" }}
                            />
                        </a>
                        <a href="https://api.whatsapp.com/send?phone=+6281931373646&text=Hello,Admin saya ingin menanyakan hal mengenai bukubon" className="wa admin 2" style={{marginLeft:"15px",marginRight:"20px"}}>
                            <FontAwesomeIcon
                              icon={faWhatsapp}
                              style={{ fontSize: 20, color: "#ffc451" }}
                            />
                        </a>
                        <a href="https://api.whatsapp.com/send?phone=+6281318000822&text=Hello,Admin saya ingin menanyakan hal mengenai bukubon" className="wa admin 3" style={{marginLeft:"15px",marginRight:"20px"}}>
                            <FontAwesomeIcon
                              icon={faWhatsapp}
                              style={{ fontSize: 20, color: "#ffc451" }}
                            />
                        </a>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
}

export default HeaderContact;
