import React from "react";
import fnb from './../../assets/images/services/fnb.png';
import wholesaleandretail from './../../assets/images/services/wholesaleandretail.png';
import barber from './../../assets/images/services/barber.png';
import workshop from './../../assets/images/services/workshop.png';
import warehouse from './../../assets/images/services/warehouse.png';
import tax from './../../assets/images/services/tax.png';

function ServiceContent() {
  return (
        
        <div className="container">
          <div className="flex justify-center">
            <h2 className="py-4 font-semibold text-gray-500 lg:py-8 lg:text-4xl">
              Aplikasi untuk berbagai jenis usaha.
            </h2>
          </div>
          
            
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                    <img src={fnb} alt="food and beverage" className="img-fluid img-responsive"/>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <h3 style={{marginTop:"50px"}}>FOOD & BEVERAGES</h3>
                    <p>
                      BukuBon memudahkan perkembangan bisnis F&B and lebih cepat tanpa harus membuat catatan manual.
                      Cukup dengan 1 aplikasi dapat mengatur menu,harga,keuntungan,stok,penjualan,dll.
                    </p>
                </div>
            </div>
            <p>&nbsp;</p>
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                    <h3 style={{marginTop:"0px"}}>WHOLESALE & RETAIL</h3>
                    <p>
                      Dengan 1 aplikasi memiliki kemampuan multi tasking mulai dari transaksi,stok ketersediaan barang hingga
                      management keuangan dapat membantu kemajuan BISNIS ANDA.
                    </p>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <img src={wholesaleandretail} alt="wholesale and retail" className="img-fluid img-responsive"/>
                </div>
            </div>
            <p>&nbsp;</p>
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                    <img src={barber} alt="barber shop" className="img-fluid img-responsive"/>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <h3 style={{marginTop:"0px"}}>BARBER SERVICES</h3>
                    <p>
                      Monitor jasa barber atau salon,sekaligus mantau produk yang jasa yang terlaris,untuk membantu meningkatkan 
                      penjualan anda.
                    </p>
                </div>
            </div>
            <p>&nbsp;</p>
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                    <h3 style={{marginTop:"0px"}}>BENGKEL atau JASA</h3>
                    <p>
                      Monitor Bengkel atau jasa anda secara realtime , dan bisa lebih mengerti apa yang di keluhkan dan di inginkan
                      oleh customer . Memantau semua itu dengan mudah dan secara real time.
                    </p>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <img src={workshop} alt="barber shop" className="img-fluid img-responsive"/>
                </div>
            </div>
            <p>&nbsp;</p>
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                    <img src={warehouse} alt="barber shop" className="img-fluid img-responsive"/>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <h3 style={{marginTop:"0px"}}>WAREHOUSE MANAGEMENT</h3>
                    <p>
                      Memonitor INBOUND dan OUTBOUND barang bisnis dengan mudah , memberikan kemudahan untuk memaksimalkan bisnis
                      anda lebih efisien dalam waktu dan memaksimalkan kinerja bisnis untuk mendapat potensi barang terlaris.
                    </p>
                </div>
            </div>
            <p>&nbsp;</p>
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                    <h3 style={{marginTop:"0px"}}>TAX MANAGEMENT</h3>
                    <p>
                      Kemudahan dalam menghitung pajak bisnis anda dalam 1 aplikasi.
                    </p>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <img src={tax} alt="tax management" className="img-fluid img-responsive"/>
                </div>
            </div>
            
          
         
        </div>
     
  );
}

export default ServiceContent;
