import React from "react";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook,faTwitter,faInstagram,faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import paymentmethod from './../../assets/images/footer/paymentmethod.png';

const Footer = () => {
  return (
    <section id="footer">
       <div className="footer-top">
        <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                  <div className="footer-info">
                      <img
                        src="assets/images/bkb-logo-white-sm.png"
                        alt="bukubon"
                        width={163}
                        height={120}
                      />
                      <div className="social-links mt-3" style={{marginLeft:"25px"}}>
                        <a href="#" className="twitter">
                            <FontAwesomeIcon
                              icon={faTwitter}
                              style={{ fontSize: 20, color: "#ffc451" }}
                            />
                        </a>
                        <a href="#" className="facebook">
                            <FontAwesomeIcon
                              icon={faFacebook}
                              style={{ fontSize: 20, color: "#ffc451" }}
                            />
                        </a>
                        <a href="#" className="instagram">
                            <FontAwesomeIcon
                              icon={faInstagram}
                              style={{ fontSize: 20, color: "#ffc451" }}
                            />
                        </a>
                      </div>
                  </div>
              </div>
              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Links</h4>
                <ul>
                  <li>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ fontSize: 10, color: "#ffc451" }}
                    />
                    
                      <a href="#/">&nbsp; Home </a>
                    
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ fontSize: 10, color: "#ffc451" }}
                    />
                      
                        <a href="#/service">&nbsp; Service </a>
                     
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ fontSize: 10, color: "#ffc451" }}
                    />
                      
                        <a href="#/pricing">&nbsp; Pricing </a>
                      
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ fontSize: 10, color: "#ffc451" }}
                    />
                     
                        <a href="#/contact">&nbsp; Contact </a>
                      
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ fontSize: 10, color: "#ffc451" }}
                    />
                     
                        <a href="#/login">&nbsp; Login </a>
                      
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ fontSize: 10, color: "#ffc451" }}
                    />
                     
                        <a href="#/register">&nbsp; Register </a>
                      
                  </li>
                </ul>
              </div> 
              <div className="col-lg-3 col-md-6">
                  <p style={{margin:"0 auto",textAlign:"center"}}>&nbsp;</p>
                  <div className="social-links mt-3">
                    <a href="https://api.whatsapp.com/send?phone=+6281314399923&text=Hello,Admin saya ingin menanyakan hal mengenai bukubon" className="wa admin 1" style={{marginLeft:"0px",marginRight:"20px"}}>
                        <FontAwesomeIcon
                          icon={faWhatsapp}
                          style={{ fontSize: 20, color: "#FFFFFF" }}
                        />
                    </a>
                    <a href="https://api.whatsapp.com/send?phone=+6281931373646&text=Hello,Admin saya ingin menanyakan hal mengenai bukubon" className="wa admin 2" style={{marginLeft:"15px",marginRight:"20px"}}>
                        <FontAwesomeIcon
                          icon={faWhatsapp}
                          style={{ fontSize: 20, color: "#FFFFFF" }}
                        />
                    </a>
                    <a href="https://api.whatsapp.com/send?phone=+6281318000822&text=Hello,Admin saya ingin menanyakan hal mengenai bukubon" className="wa admin 3" style={{marginLeft:"15px",marginRight:"20px"}}>
                        <FontAwesomeIcon
                          icon={faWhatsapp}
                          style={{ fontSize: 20, color: "#FFFFFF" }}
                        />
                    </a>
                  </div>
              </div>
              <div className="col-lg-3 col-md-6">
                  <img src={paymentmethod}
                    className="img-fluid img-responsive"
                  />
              </div>
            </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
