import React from "react";

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import prince from './../../assets/images/clients/prince.png';
import gulsoy from './../../assets/images/clients/gulsoy.png';
import umamaws from './../../assets/images/clients/umamaws.png';
import sk from './../../assets/images/clients/sk.jpg';

const Clients = () => {
  return (
   
    <section id="clients" className="clients">
      <div className="container" data-aos="zoom-in">
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={2}
          slidesPerView={8}
          
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          
        >
          <SwiperSlide>
              <img
                style={{maxHeight:"100px"}}
                src={umamaws}
                alt="umama wholesale"
                className="img-fluid img-responsive"
              />
          </SwiperSlide>
          <SwiperSlide>
              <img
                style={{maxHeight:"100px"}}
                src={gulsoy}
                alt="gulsoy"
                className="img-fluid img-responsive"
              />
          </SwiperSlide>
          <SwiperSlide>
              <img
                style={{maxHeight:"100px"}}
                src="assets/images/clients/umnushel.jpg"
                alt="umama"
                className="img-fluid img-responsive"
              />
          </SwiperSlide>
          <SwiperSlide>
              <img
                style={{maxHeight:"100px"}}
                src="assets/images/clients/yeffa-sm.png"
                alt="yeffa"
                className="img-fluid img-responsive"
              />
          </SwiperSlide>
          <SwiperSlide>
              <img
                style={{maxHeight:"100px"}}
                src="assets/images/clients/ar.png"
                alt="ar jewelry"
                className="img-fluid img-responsive"
              />
          </SwiperSlide>
          <SwiperSlide>
              <img
                style={{maxHeight:"100px"}}
                src="assets/images/clients/eti.png"
                alt="eti kerudung"
                className="img-fluid img-responsive"
              />
          </SwiperSlide>
          <SwiperSlide>
              <img
                style={{maxHeight:"100px"}}
                src="assets/images/clients/nushel.png"
                alt="nushel"
                className="img-fluid img-responsive"
              />
          </SwiperSlide>
          <SwiperSlide>
              <img
                style={{maxHeight:"100px"}}
                src="assets/images/clients/evitare.png"
                alt="evitare"
                className="img-fluid img-responsive"
              />
          </SwiperSlide>
          <SwiperSlide>
              <img
                style={{maxHeight:"100px"}}
                src="assets/images/clients/sahnu.png"
                alt="pt sahnu jaya indo"
                className="img-fluid img-responsive"
              />
          </SwiperSlide>
          <SwiperSlide>
              <img
                style={{maxHeight:"100px"}}
                src="assets/images/clients/soylumercan.jpg"
                alt="soylu mercan indonesia"
                className="img-fluid img-responsive"
              />
          </SwiperSlide>
          <SwiperSlide>
              <img
                style={{maxHeight:"100px"}}
                src="assets/images/clients/gju.jpg"
                alt="GJU Gulsoy"
                className="img-fluid img-responsive"
              />
          </SwiperSlide>
          <SwiperSlide>
              <img
                style={{maxHeight:"100px"}}
                src="assets/images/clients/mafme-sm.png"
                alt="afmer"
                className="img-fluid img-responsive"
              />
          </SwiperSlide>
          <SwiperSlide>
              <img
                style={{maxHeight:"100px"}}
                src={prince}
                alt="prince barbershop"
                className="img-fluid img-responsive"
              />
          </SwiperSlide>
          <SwiperSlide>
              <img
                style={{maxHeight:"100px"}}
                src={sk}
                alt="sk wholesale"
                className="img-fluid img-responsive"
              />
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
    
  );
};

export default Clients;
