import React from 'react';
import { useRoutes } from 'react-router';
import MainLayout from './layouts/MainLayout';
import Home from './pages/Home';
import Service from './pages/Service/service';
import Pricing from './pages/Pricing/pricing';
import Contact from './pages/Contact/contact';
import Login from './pages/Login/login';
import Register from './pages/Login/register';

export default function Routes() {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '/',
          element: <Home />
        },
        {
          path: '/service',
          element: <Service />
        },
        {
          path: '/pricing',
          element: <Pricing />
        },
        {
          path: '/contact',
          element: <Contact />
        },
        {
          path: '/login',
          element: <Login />
        },
        {
          path: '/register',
          element: <Register />
        },
      ]
    }
  ]);
}