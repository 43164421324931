import React from "react";
import imagemockup from './../../assets/images/services/imagemockup.png';

function Service() {
  return (
    <section id="service" className="d-flex align-items-center justify-content-center">
    <div className="container" data-aos="fade-up">

      <div className="row justify-content-left" data-aos="fade-up" data-aos-delay="150">
        <div className="col-xl-6 col-lg-8">
          <h1>Our Services <span>.</span></h1>
          <h2>Varies from Retail to Wholesale</h2>
        </div>
        <div className="col-xl-6 col-lg-6">
            <img
              src={imagemockup}
              alt="BB Icon"
              className="img-fluid img-responsive"
              
            />
        </div>
      </div>


    </div>
  </section>
  );
}

export default Service;
