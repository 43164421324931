import React from 'react';
import { HashRouter as Router } from 'react-router-dom';



import Routes from './Routes';


import Loading from './components/Loading';


import 'bootstrap/dist/css/bootstrap.css';

import "@fortawesome/fontawesome-svg-core/styles.css"; 
import { config } from "@fortawesome/fontawesome-svg-core";
import { useEffect } from "react";


config.autoAddCss = false;

function App() {
  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");
  }, []);
  return (
   
        <Router>
          <Routes />
          <Loading />
          
        </Router>
    
  );
}

export default App;
