import React from "react";


function Hero() {
  return (
    <section id="pricing" className="d-flex align-items-center justify-content-center">
      <div className="container" data-aos="fade-up">
        <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
          <div className="col-xl-6 col-lg-8">
            <h1>Pricing Plan <span>.</span></h1>
            <h2>Silahkan Pilih Pricing plan anda,mulai dari basic plan sampai dengan enterprise plan.</h2>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
