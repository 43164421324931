import React from "react";
import proprice from './../../assets/images/pricing/proprice.png';
import basicprice from './../../assets/images/pricing/basicprice.png';
import enterpriseprice from './../../assets/images/pricing/enterpriseprice.png';

function PricingContent() {
  return (
   
        <div className="container">
          <div className="flex justify-center">
            <h2 className="py-4 font-semibold text-gray-500 lg:py-8 lg:text-4xl">
              Temukan paket harga sesuai dengan kebutuhan anda
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box">
                <img
                  src={basicprice}
                  alt="bukubon basic pricing"
                  style={{maxWidth:"400px"}}
                  className="img-fluid img-responsive"
                />
                <h4>&nbsp;</h4>
                <p>&nbsp;</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box">
                <img
                  src={proprice}
                  alt="bukubon pro pricing"
                  style={{maxWidth:"400px"}}
                  className="img-fluid img-responsive"
                />
                <h4>&nbsp;</h4>
                <p>&nbsp;</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box">
                <img
                  src={enterpriseprice}
                  alt="bukubon enterprise pricing"
                  style={{maxWidth:"400px"}}
                  className="img-fluid img-responsive"
                />
                <h4>&nbsp;</h4>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
          
          
         
        </div>
      
  );
}

export default PricingContent;
