import React from "react";
import oneapp from './../../assets/images/home/oneapp.png';
import manageapp from './../../assets/images/home/manageapp.png';
import realtimereport from './../../assets/images/home/realtimereport.png';

const Feature = () => {
  return (
    <>
    <section
      id="services"
      className="services"
    >
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Bukubon</h2>
          <p>Not a NonSense App.
              , Purely created with heart for your business.</p>
        </div>
        <div className="row">
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box">
                <img
                  src={oneapp}
                  alt="single app"
                  width="100"
                  height="100"
                  
                />
                <h4>One Apps For All Business</h4>
                <p>Manage Your Business in one Integrated App.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box">
                <img
                  src={manageapp}
                  alt="monitoring"
                  width="100"
                  height="100"
                />
                <h4>Monitor your Business</h4>
                <p>Minitor your Business anywhere , anytime easily.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box">
                <img
                  src={realtimereport}
                  alt="real time report"
                  width="100"
                  height="100"
                />
                <h4>Report that relax you</h4>
                <p>Analyse your business realtime , accurately.</p>
              </div>
            </div>
        </div>

      </div>
    </section>
    <section id="cta" className="cta">
      <div className="container" data-aos="zoom-in">

        <div className="text-center">
          <h3>Call To Action</h3>
          <p> Ask for a Demo or Trial.</p>
          <a className="cta-btn" href="https://api.whatsapp.com/send?phone=+6281314399923&text=Hello,Admin saya ingin menanyakan hal mengenai bukubon">Call To Action</a>
        </div>

      </div>
    </section>
  </>
  );
};

export default Feature;
