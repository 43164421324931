import React from "react";
import HeaderContact from "../../components/contact/HeaderContact";


const contact = () => {
  return (
    <div>
      
      <HeaderContact/>
      
    </div>
  );
};

export default contact;
