import React, { useState } from "react";
import logo from './../../assets/images/logo.png';
//import axios from 'axios';
import axios from './../../app/axiosbb';

function LoginForm() {
    const [contentdata, setContentdata] = useState('');
    const [errormessage, setErrormessage] = useState('');
    const handleChange = (event) => {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        //const element = document.getElementById(target.id);
    
        //validateInputchange(element,target,name,value);
        setContentdata({ ...contentdata, [name]: value });
        //console.log(contentdata);
    }

    function handleSave() {
        let jsonData =  JSON.stringify(contentdata);
        //console.log(jsonData);
        //console.log(jsonData.length);
        if(jsonData==='""')
        {
            setErrormessage("Please filled in the form");
            return;
        }

        const obj = JSON.parse(jsonData);
        //console.log(obj.UserName);
        if(typeof obj ==="undefined")
        {
            setErrormessage("Please filled in the form");
            return;
        }

        if(typeof obj.UserName==="undefined")
        {
            setErrormessage("Please filled in the form");
            return;
        }

        if(obj.UserName==="" || obj.UserName.length<=0)
        {
            setErrormessage("Please filled in the form");
            return;
        }

        if(typeof obj.Password==="undefined")
        {
            setErrormessage("Please filled in the form");
            return;
        }

        if(obj.Password==="" || obj.Password.length<=0)
        {
            setErrormessage("Please filled in the form");
            return;
        }

        setErrormessage("Success");

        let url="/user/login";

        return axios.post(url, jsonData).then(res => {
            if (res.status === 200 || res.status === 201) {
                console.log(res.data.access_token);
                window.localStorage.setItem('token', res.data.access_token);
                window.localStorage.setItem('tenantID', res.data.tenantID);
                window.location.href = res.data.UIUrlCode;
                //window.location.replace(res.data.UIUrlCode);
            }
            else{
                setErrormessage("Invalid Username or Password");
            }
        }).catch(err => {
            //console.log(err);
            //let errMessage ="";
            //console.log(err.data.message);
            //if(err.data.message === null || typeof err.data.message === "undefined")
            //{
                //errMessage = "Complete the data";
            // }
            // else {
            //     errMessage = err.data.message;
            // }
            
                setErrormessage("Complete the data,or data is invalid");  
              
              return ;
          });
    
        //return;
    
      }

  return (
    <section id="contact" className="contact">
        <div className="container" data-aos="fade-up" style={{marginTop:"30px"}}>
            <div className="row d-flex justify-content-center align-items-center h-100">
                
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body" style={{padding:"unset"}}> 
                                <div className="row">
                                    <div className="col-lg-6 text-center" style={{padding:"30px"}}>
                                        <div className="text-center">
                                            <img src={logo}
                                                style={{width:"185px"}}alt="logo"/>
                                            <h4 className="mt-1 mb-5 pb-1">&nbsp;</h4>
                                        </div>
                                        <form>
                                            <p>Please login to your account</p>
                                            <div className="form-outline mb-4">
                                                <input type="text" name ="UserName" id="UserName" className="form-control"
                                                    placeholder="Phone number or email address" defaultValue=""
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-outline mb-4">
                                                <input type="Password" name="Password" id="Password" className="form-control" 
                                                    placeholder="Password" 
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="text-center">
                                                <div style={{color: 'red'}}>
                                                    {errormessage}
                                                </div>
                                                <button className="btn gradientcustom" type="button" 
                                                    onClick={handleSave}
                                                    style={{width:"150px",color:"white"}}>
                                                    Login
                                                </button><br/>
                                                <a className="text-muted" href="/#">Forgot password?</a>
                                            </div>
                                            <br/>
                                            <div className="d-flex align-items-center justify-content-center pb-4">
                                                <p className="mb-0 me-2">New to BukuBon?</p>
                                                <a className="text-muted" href="#/register">Register</a>
                                                
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-lg-6 d-flex align-items-center">
                                        <div className="gradientcustom">
                                            <div className="d-flex flex-column  justify-content-center gradient-custom-2 h-100 mb-4">
                                                <div className="text-white text-align-center px-3 py-4 p-md-5 mx-md-4">
                                                    <h4 className="mb-4 text-center">Login or Register</h4>
                                                    <p className="small mb-0 text-center">Login or register , you are ONE step away from getting pampered
                                                    by our no nonsense app. Manage your business easily , get analyse your business anytime , anywhere and dont forget to drop us testimony.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        
                        
                    
                
            </div>
        </div>
    </section>
  );
}

export default LoginForm;
