import React from "react";
import Login from "../../components/login/LoginForm";


const login = () => {
  return (
    <div>
      
      <Login/>
      
    </div>
  );
};

export default login;
