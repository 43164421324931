import React from "react";
import Service from "../../components/service/Service";
import ServiceContent from "../../components/service/ServiceContent";

const service = () => {
  return (
    <div>
      
      <Service/>
      <ServiceContent/>
      
    </div>
  );
};

export default service;
