import React from 'react';
import { Outlet } from 'react-router';
import { Box } from '@mui/material';


import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

export default function MainLayout() {
  return (
    <Box className="bg-main" minHeight="100vh">
      <Header/>
      <Outlet />
      <Footer />
    </Box>
  );
}