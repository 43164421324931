import React from 'react';

import Clients from "../../components/home/Clients";
import Feature from "../../components/home/Feature";
import Hero from "../../components/home/Hero";



export default function Home() {
  

  return (
    <>
      <Hero />
      <Clients />
      <Feature />
    </>
  );
}