import React from "react";


import Hero from "../../components/pricing/Hero";
import PricingContent from "../../components/pricing/PricingContent";

const pricing = () => {
  return (
    <div>
      
      <Hero />
      <PricingContent/>
      
    </div>
  );
};

export default pricing;
