import React from "react";
import RegisterForm from "../../components/login/RegisterForm";


const register = () => {
  return (
    <div>
      
      <RegisterForm/>
      
    </div>
  );
};

export default register;
